<template>
  <section
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="social-networks"
  >
    <div class="copy">
      <p class="copy-content">{{ slice.primary.copy }}</p>
      <div class="networks">
        <template v-for="network in slice.primary.networks" :key="network.name">
          <a class="network-link" :href="network.url" target="_blank">{{
            network.name
          }}</a>
        </template>
      </div>
    </div>
    <a
      target="_blank"
      href="https://www.instagram.com/duchateauofficial/?hl=fr"
    >
      <div class="posts">
        <div
          v-for="(image, index) in displayedImages"
          :key="index"
          class="post-item"
        >
          <div class="post-item--image">
            <CustomImage :image="image.image" :sizes="[83, 94]" />
          </div>
        </div>
      </div>
    </a>
  </section>
</template>

<script setup lang="ts">
import { type Content } from "@prismicio/client";

import { useMobile } from "@/composables/useMobile";
const { isMobile } = useMobile();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.SocialNetworksSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);

const displayedImages = computed(() => {
  if (isMobile.value) {
    return props.slice.primary.instagram_image.slice(0, 8);
  }
  return props.slice.primary.instagram_image;
});
</script>

<style lang="scss" scoped>
.social-networks {
  padding: 0 var(--padding-mobile) 8rem;
  background-color: $white;
  @include desktop {
    padding: 0 calc($padding-desktop + 100% / 12) $padding-desktop-big;
  }
}

.copy {
  display: flex;
  @include desktop {
    gap: 1.2rem;
  }
}

.copy-content,
.networks {
  width: 50%;
}

.copy-content {
  @include caption-small;
}

.networks {
  display: flex;
  flex-wrap: wrap;
  max-width: 16rem;
  gap: 0 0.13rem;
  position: relative;
  padding-left: 0.6rem;
  @include desktop {
    padding-left: 0;
  }
}

.network-link {
  @include link;
  @include link-active;
  text-transform: uppercase;
  &:not(:last-child) {
    &::after {
      content: ",";
    }
  }
}

.posts {
  margin-top: 2rem;

  @include grid-mobile;

  @include desktop {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    gap: 2.4rem;
  }
}

.row {
  display: flex;
  gap: 1.2rem;
  @include desktop {
    width: 50%;
    gap: 2.4rem;
    margin-bottom: unset;
  }
}

.first-row {
  margin-bottom: 1.2rem;
  @include desktop {
    margin-bottom: 0;
    justify-content: flex-start;
  }
}

.second-row {
  @include desktop {
    margin-bottom: unset;
    justify-content: flex-start;
  }
}

.second-row .post-item:last-child {
  margin-right: 0;
}

.post-item {
  grid-column: span 3;
  margin-bottom: 1.2rem;
  aspect-ratio: 1;

  @include desktop {
    flex: 1 1 auto;
  }
}

.post-item:not(:last-child) {
  @include desktop {
  }
}

.post-item--image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include desktop {
    clip-path: inset(0%);
    transition: clip-path 3.5s cubic-bezier(0.01, 0.95, 0.21, 1);
    overflow: hidden;
    &:hover {
      clip-path: inset(5%);
    }
  }
}
</style>
